exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-banxa-tsx": () => import("./../../../src/pages/banxa.tsx" /* webpackChunkName: "component---src-pages-banxa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mercuryo-tsx": () => import("./../../../src/pages/mercuryo.tsx" /* webpackChunkName: "component---src-pages-mercuryo-tsx" */),
  "component---src-pages-moonpay-tsx": () => import("./../../../src/pages/moonpay.tsx" /* webpackChunkName: "component---src-pages-moonpay-tsx" */),
  "component---src-pages-onramper-banxa-tsx": () => import("./../../../src/pages/onramper/banxa.tsx" /* webpackChunkName: "component---src-pages-onramper-banxa-tsx" */),
  "component---src-pages-onramper-tsx": () => import("./../../../src/pages/onramper.tsx" /* webpackChunkName: "component---src-pages-onramper-tsx" */),
  "component---src-pages-united-kingdom-tsx": () => import("./../../../src/pages/united-kingdom.tsx" /* webpackChunkName: "component---src-pages-united-kingdom-tsx" */),
  "component---src-pages-wallet-index-tsx": () => import("./../../../src/pages/wallet/index.tsx" /* webpackChunkName: "component---src-pages-wallet-index-tsx" */),
  "component---src-pages-wallet-provider-tsx": () => import("./../../../src/pages/wallet/provider.tsx" /* webpackChunkName: "component---src-pages-wallet-provider-tsx" */),
  "component---src-views-seo-pages-template-tsx": () => import("./../../../src/views/SeoPages/Template.tsx" /* webpackChunkName: "component---src-views-seo-pages-template-tsx" */)
}

